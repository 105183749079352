import style from './Header.module.scss'
import {Link} from "react-router-dom";

function Header (){
    return(
        <div className={style.main}>
            <div className={style.container}>
                <div className={style.backmain}></div>
                <div className={style.content}>
                    <div className={style.nav}>
                        <div className={style.linker}>
                            <Link to='/about' className={style.link}>О НАС</Link>
                            <Link to='/about' className={style.link}>АРТИСТЫ</Link>
                            <Link to='/about' className={style.link}>ВОЗМОЖНОСТИ</Link>
                            <Link to='/about' className={style.link}>BLOG</Link>
                        </div>
                        <div className={style.burger}>
                            <div className={style.box}>
                                <div className={style.slash}></div>
                                <div className={style.slash}></div>
                                <div className={style.slash}></div>
                            </div>
                        </div>
                    </div>
                    <div className={style.logos}>
                        <img src="/files/omedia.svg" className={style.omedialogo}/>
                        <img src="/files/omediamusic.svg" className={style.musiclogo}/>
                    </div>
                    <div className={style.title}>Поиск новых артистов. Продвижение молодых талантов</div>
                </div>
            </div>
        </div>
    )
}

export default Header