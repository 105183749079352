import style from './FormBid.module.scss'

function FormBid({setActivemodal,active}){
    return(
        <div className={style.main}>
            <div className={style.cap}>
                <div className={style.title}>Отправить демо</div>
                <div className={style.desc}>Мы в поиске талантливых молодых артистов, готовых на долгосрочное сотрудничество. </div>
            </div>
            <div className={style.form}>
                <div className={style.title}>Email</div>
                <input type="text" placeholder='Email' className={style.line}/>
                <div className={style.title}>Имя исполнителя</div>
                <input type="text" placeholder='Имя исполнителя' className={style.line}/>
                <div className={style.title}>Название релиза</div>
                <input type="text" placeholder='Название релиза' className={style.line}/>
                <div className={style.title}>Ссылка на основную соц.сеть исполнителя</div>
                <input type="text" placeholder='Ссылка на основную соц.сеть исполнителя' className={style.line}/>
                <div className={style.title}>Ссылка на материал (релизы, демо)</div>
                <input type="text" placeholder='Ссылка на материал (релизы, демо)' className={style.line}/>
                <div className={style.storyblock}>
                    <div className={style.title}>Расскажите о своих планах</div>
                    <textarea className={style.text}></textarea>
                </div>
                <div className={style.btn}>Отправить</div>
            </div>
        </div>
    )
}
export default FormBid