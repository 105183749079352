import style from './SmallHeader.module.scss'
import {Link} from "react-router-dom";

function SmallHeader (){
    return(
        <div className={style.main}>
            <div className={style.container}>
                <div className={style.backmain}></div>
                <div className={style.content}>
                    <div className={style.nav}>
                        <div className={style.linker}>
                            <Link to='/'><img src="/files/omediamusic.svg" className={style.musiclogo}/></Link>
                            <Link to='/about' className={style.link}>HOME</Link>
                            <Link to='/about' className={style.link}>ABOUT</Link>
                            <Link to='/about' className={style.link}>COURSES</Link>
                            <Link to='/about' className={style.link}>BLOG</Link>
                            <Link to='/about' className={style.link}>SING IN</Link>
                        </div>
                        <div className={style.burger}>
                            <div className={style.box}>
                                <div className={style.slash}></div>
                                <div className={style.slash}></div>
                                <div className={style.slash}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SmallHeader