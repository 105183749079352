import style from './styles/Main.module.scss'
import FooterBlock from "../components/footer/Footer";
import SmallHeader from "../components/header/SmallHeader";
import AboutBlock from "../components/about/AboutBlock";

function Main () {


    return (
        <div className={style.bodymain}>
            <SmallHeader />
            <div className={style.main}>
                <div className={style.container}>
                    <AboutBlock />
                </div>
            </div>
            <FooterBlock />
        </div>
    )
}

export default Main